// Generated by Framer (005eb18)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {Qbh7mB5_Z: {pressed: true}};

const cycleOrder = ["kbQrHtJJR", "Qbh7mB5_Z"];

const variantClassNames = {kbQrHtJJR: "framer-v-1kg1g8", Qbh7mB5_Z: "framer-v-1mocxfg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "kbQrHtJJR", Hover: "Qbh7mB5_Z"}

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, EuKPntm0L: link ?? props.EuKPntm0L, T_dGb4vrs: icon ?? props.T_dGb4vrs ?? "FacebookLogo", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kbQrHtJJR"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EuKPntm0L, T_dGb4vrs, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kbQrHtJJR", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterrlhmxs = activeVariantCallback(async (...args) => {
setVariant("Qbh7mB5_Z")
})

const onMouseLeavex7j6a2 = activeVariantCallback(async (...args) => {
setVariant("kbQrHtJJR")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vIMzC", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={EuKPntm0L}><motion.a {...restProps} className={`${cx("framer-1kg1g8", className)} framer-vuoazo`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"kbQrHtJJR"} onMouseEnter={onMouseEnterrlhmxs} ref={ref} style={{backgroundColor: "var(--token-67fbec5f-f86d-4a68-8709-0a9a96756c72, rgba(255, 255, 255, 0.1))", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32, ...style}} {...addPropertyOverrides({"Qbh7mB5_Z-pressed": {"data-framer-name": undefined}, Qbh7mB5_Z: {"data-framer-name": "Hover", onMouseLeave: onMouseLeavex7j6a2}}, baseVariant, gestureVariant)}><motion.div className={"framer-7cyoat-container"} layoutDependency={layoutDependency} layoutId={"yYij79tD3-container"}><Phosphor color={"var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(250, 245, 234)) /* {\"name\":\"Off White\"} */"} height={"100%"} iconSearch={"House"} iconSelection={T_dGb4vrs} id={"yYij79tD3"} layoutId={"yYij79tD3"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"} {...addPropertyOverrides({"Qbh7mB5_Z-pressed": {weight: "fill"}, Qbh7mB5_Z: {color: "var(--token-9f0badbf-8d74-41b8-a73a-e1a6bdec487b, rgb(255, 132, 66)) /* {\"name\":\"Orange\"} */", weight: "duotone"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vIMzC [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vIMzC .framer-vuoazo { display: block; }", ".framer-vIMzC .framer-1kg1g8 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 40px; }", ".framer-vIMzC .framer-7cyoat-container { flex: none; height: 26px; position: relative; width: 26px; }", ".framer-vIMzC .framer-v-1mocxfg .framer-1kg1g8 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vIMzC .framer-1kg1g8 { gap: 0px; } .framer-vIMzC .framer-1kg1g8 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-vIMzC .framer-1kg1g8 > :first-child { margin-left: 0px; } .framer-vIMzC .framer-1kg1g8 > :last-child { margin-right: 0px; } }", ".framer-vIMzC.framer-v-1mocxfg .framer-7cyoat-container { height: 36px; width: 36px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Qbh7mB5_Z":{"layout":["fixed","fixed"]},"Q5bY45kRK":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"EuKPntm0L":"link","T_dGb4vrs":"icon"}
 */
const FramerxfC6Kqmvr: React.ComponentType<Props> = withCSS(Component, css, "framer-vIMzC") as typeof Component;
export default FramerxfC6Kqmvr;

FramerxfC6Kqmvr.displayName = "Icon - Social Media";

FramerxfC6Kqmvr.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerxfC6Kqmvr, {variant: {options: ["kbQrHtJJR", "Qbh7mB5_Z"], optionTitles: ["Default", "Hover"], title: "Variant", type: ControlType.Enum}, EuKPntm0L: {title: "Link", type: ControlType.Link}, T_dGb4vrs: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "FacebookLogo", hidden: undefined, title: "Icon"}} as any)

addFonts(FramerxfC6Kqmvr, [...PhosphorFonts])